import HeroMain from '@/design-system/molecules/HeroMain';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const IntegrationHeroMain = (props) => {
    const modifiers = generateStyleModifiers(props.appliedCssClassNames);
    const theme = modifiers.theme;

    return <HeroMain {...props} theme={theme} />;
};

export default IntegrationHeroMain;
