import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Breadcrumb } from '@/design-system/atoms/Breadcrumb';
import { Button } from '@/design-system/atoms/Button';
import { CardVideo } from '@/design-system/atoms/Cards/CardVideo';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import HeroZipForm from '@/design-system/molecules/HeroZipForm';

import styles from './HeroMain.module.scss';

/**
 * The Hero Main Component purpose is to introduce the user to the page,
 * brand, and/or site. Hero banner content should be concise and easy to
 * digest and drive the user further down the page or into a key section
 * of the site.
 */
const HeroMain = ({
    testId,
    breadcrumbs,
    grid,
    eyebrow,
    title,
    body,
    text,
    ctas,
    media,
    image,
    video,
    theme = Theme.NAME.SPECIALTIES,
    enableLocationFieldForSearch,
    zipCityPlaceHolder,
    zipCtaAriaLabel,
    zipCtaHref,
    zipCtaLabel,
    ...props
}) => {
    const mediaComponent = React.useMemo(() => {
        if (media === 'image' && image) {
            return (
                <Image
                    className={styles.hero__image}
                    {...image}
                    mobileAspectRatio={Image.ASPECT_RATIO['4:3']}
                    defaultAspectRatio={Image.ASPECT_RATIO['4:3']}
                    roundedCorners={true}
                />
            );
        }

        if (media === 'video' && video) {
            return (
                <CardVideo
                    className={styles.hero__video}
                    {...video}
                    analytics={{ componentTitle: title }}
                />
            );
        }

        return null;
    }, [media, image, video, title]);

    return (
        <section data-testid={testId} className={styles.hero}>
            <Theme name={theme}>
                <ContentContainer>
                    <div className={styles['hero__spacing-wrapper']}>
                        <Grid verticalAlignment="center">
                            {breadcrumbs && (
                                <GridColumn
                                    className={styles.hero__breadcrumb}
                                    colSpan={{ sm: 4, md: 6, lg: 12 }}
                                    colStart={{ sm: 1, lg: 1 }}
                                >
                                    <Breadcrumb
                                        items={breadcrumbs}
                                        analytics={{
                                            componentName: 'Hero Main',
                                            componentTitle: title,
                                        }}
                                    />
                                </GridColumn>
                            )}
                            <GridColumn
                                className={styles.hero__primary}
                                colSpan={{ sm: 4, md: 6, lg: 6 }}
                                colStart={{ sm: 1, lg: 1 }}
                            >
                                <div className={styles.hero__content}>
                                    {eyebrow && (
                                        <Text
                                            tag={Text.TAG.P}
                                            variant={Text.VARIANT.E1}
                                            content={eyebrow}
                                        />
                                    )}
                                    <div className={styles['hero-primary__title']}>
                                        <Title
                                            tag="h1"
                                            variant={Title.VARIANT.H2}
                                            content={title}
                                            lineClamp={{ sm: 3, lg: 3 }}
                                        />
                                    </div>
                                    {body && (
                                        <div className={styles['hero__body']}>
                                            <Text
                                                variant={Text.VARIANT.H5}
                                                content={body}
                                                lineClamp={{ sm: 12, lg: 8 }}
                                            />
                                        </div>
                                    )}

                                    {enableLocationFieldForSearch ? (
                                        <HeroZipForm
                                            zipCityPlaceHolder={zipCityPlaceHolder}
                                            zipCtaAriaLabel={zipCtaAriaLabel}
                                            zipCtaHref={zipCtaHref}
                                            zipCtaLabel={zipCtaLabel}
                                        />
                                    ) : Array.isArray(ctas) && ctas.length > 0 ? (
                                        <div className={styles.hero__buttons}>
                                            {ctas.map((link, index) => {
                                                return (
                                                    link?.href && (
                                                        <div
                                                            className={styles.hero__button}
                                                            key={index}
                                                        >
                                                            <Button
                                                                href={link.href}
                                                                target={link.target}
                                                                csr={link.csr}
                                                                buttonStyle={
                                                                    index === 0
                                                                        ? Button.STYLE.PRIMARY
                                                                        : Button.STYLE.SECONDARY
                                                                }
                                                                label={link.label}
                                                                ariaLabel={link.ariaLabel}
                                                                analytics={{
                                                                    componentName: 'Hero Main',
                                                                    componentTitle: title,
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            </GridColumn>
                            <GridColumn
                                className={styles.hero__secondary}
                                colSpan={{ sm: 4, md: 6, lg: 6 }}
                                colStart={{ sm: 1, lg: 7 }}
                            >
                                <div className={styles.hero__media}>{mediaComponent}</div>
                            </GridColumn>
                        </Grid>
                    </div>
                </ContentContainer>
            </Theme>
        </section>
    );
};

HeroMain.propTypes = {
    /** Breadcrumbs */
    breadcrumbs: PropTypes.array,
    /** Title */
    title: PropTypes.string.isRequired,
    /** Eyebrow */
    eyebrow: PropTypes.string,
    /** Description Text */
    body: PropTypes.string,
    /** CTAs */
    ctas: PropTypes.arrayOf(customPropTypes.linkPropType),
    /** Media type to display */
    media: PropTypes.oneOf(['image', 'video']),
    /** Image */
    image: PropTypes.shape(Image.propTypes),
    /** Card Video Props */
    video: PropTypes.shape(CardVideo.propTypes),
    /** Test ID */
    testId: PropTypes.string,
};

export default HeroMain;
