import { GeolocationProvider } from 'contexts/GeolocationContext';
import React from 'react';

import ZipForm from './ZipForm';

const HeroZipForm = ({ ...props }) => {
    return (
        <GeolocationProvider>
            <ZipForm {...props}></ZipForm>
        </GeolocationProvider>
    );
};

export default HeroZipForm;
