import { useGeolocationState } from 'contexts/GeolocationContext';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/design-system/atoms/Button';
import { PlacesTypeahead } from '@/design-system/atoms/Forms/PlacesTypeahead';
import { defaultValues } from '@/utils/defaultValues';

import styles from './HeroZipForm.module.scss';
import { formValidation } from './utils/formValidation';

const ZipForm = ({
    zipCityPlaceHolder,
    zipCtaAriaLabel,
    zipCtaHref,
    zipCtaLabel,
    maps,
    ...props
}) => {
    const zipRef = React.useRef();
    const [zipInvalid, setZipInvalid] = React.useState(false);
    const [zipError, setZipError] = React.useState();
    const history = useHistory();

    const geolocationState = useGeolocationState();

    const [zipCityValue, setZipCityValue] = useState(geolocationState?.recentSearch || '');

    const doRedirect = useCallback(
        (value) => {
            let isZip = zipCityValue.match(/\b\d{5}\b/g);
            let isCompleteURL = zipCtaHref.startsWith('http');
            if (zipCtaHref) {
                const url = new URL(zipCtaHref, isCompleteURL ? undefined : window.location.origin);
                url.searchParams.set(isZip ? 'zip-code' : 'city', value ?? zipCityValue);
                url.searchParams.set('sort', 'Distance');
                if (isCompleteURL) {
                    window.open(url.href, '_self');
                } else {
                    history.push(url.pathname + url.search);
                }
            }
        },
        [zipCtaHref, zipCityValue, history],
    );

    const handleSelectZipCityValue = useCallback(
        (item) => {
            if (item === null) {
                return;
            }
            doRedirect(item.text);
        },
        [doRedirect],
    );

    const submitForm = (e) => {
        e.preventDefault();

        const [isValid, keys, message] = formValidation(
            { zipCityValue },
            { zipCityValue: 'Please enter a valid ZIP code' },
        );

        if (isValid) {
            setZipInvalid(false);
            doRedirect(zipCityValue);
            return;
        }

        if (keys.includes('zipCityValue')) {
            setZipInvalid(true);
            setZipError(message);
            zipRef.current.focus();
        }
    };
    return (
        <form className={styles[`hero-zip__form`]} onSubmit={submitForm} data-trigger="search">
            <PlacesTypeahead
                ref={zipRef}
                label={zipCityPlaceHolder || 'City or Zip'}
                inputNameTypeahead={'inputNameZipCity'}
                inputValue={zipCityValue}
                onInputChange={setZipCityValue}
                onSelectionItemChange={handleSelectZipCityValue}
                onGeolocationSuccess={setZipCityValue}
                isInvalid={zipInvalid}
                validationErrors={zipError}
                className={styles[`hero-zip__typeahead`]}
                googleMaps={defaultValues(
                    {
                        apiKey: 'AIzaSyDRdNprx4liInUfIU8gA6HpPL9p4tu8NYQ',
                        mapId: 'b936a6d7221f66ee',
                    },
                    maps,
                )}
            />

            <div className={styles[`hero-zip__button-wrapper`]}>
                <Button
                    label={zipCtaLabel || 'Search'}
                    ariaLabel={zipCtaAriaLabel || 'Submit Hero Search'}
                    buttonStyle={Button.STYLE.PRIMARY}
                    className={styles.hero__button}
                    type="submit"
                    disabled={false}
                />
            </div>
        </form>
    );
};

ZipForm.propTypes = {
    zipCityPlaceHolder: PropTypes.string.isRequired,
    zipCtaAriaLabel: PropTypes.string.isRequired,
    zipCtaHref: PropTypes.string.isRequired,
    zipCtaLabel: PropTypes.string.isRequired,
    maps: PropTypes.object,
};

export default ZipForm;
