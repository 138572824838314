const zipRegex = RegExp(`^[0-9]{5}(?:-[0-9]{4})?$`);

export const formValidation = ({ zipCityValue }, errorMessage = {}) => {
    if (zipCityValue && !isNaN(zipCityValue) && !zipRegex.test(zipCityValue)) {
        return [
            false,
            ['zipCityValue'],
            errorMessage.zipCityValue ?? 'Please enter a valid ZIP code',
        ];
    }

    return [true];
};
